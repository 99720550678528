import * as React from "react"
import type { HeadFC } from "gatsby"
import Layout from "../components/layout"
import '../styles/global.css'

const pageStyles = {
  color: "#232129",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

const IndexPage = () => {
  return (
    <main  className="bg-white" style={pageStyles}>
      <Layout>

        <section className="flex items-center h-auto py-20 justify-center bg-white">
          <div className="mx-auto max-w-[43rem]">
            <div className="text-center">
              <h1 className="text-[3.5rem] font-bold leading-[4rem] tracking-tight text-black">
                Las donaciones son <span className="underlined">100%</span> deducibles de impuestos
              </h1>
              <p className="mt-2 text-md leading-relaxed text-slate-400">
                Brindar atención especializada y comprometida a niños que enfrentan  problemas de aprendizaje, 
                TDA-H y/o discapacidad intelectual en un  marco escolarizado.
              </p>
            </div>

            <div className="mt-6 flex items-center justify-center gap-4">
              <a href="https://www.paypal.com/donate/?hosted_button_id=C5YJH4FF9X52W" 
                className="transform rounded-md bg-blue-600/95 px-5 py-3 font-medium 
                  text-white transition-colors hover:bg-yellow-400 hover:text-blue-600">Donar Por Paypal</a>
            </div>
          </div>
        </section>

        <div className="w-full mx-auto bg-yellow-300 h-auto">
          <div className="flex-auto w-full p-10 text-black">
            <h1 className="text-left text-4xl font-semibold">Otros métodos para donar</h1>
          </div>

          <div className="w-full p-10 grid grid-col md:grid-cols-3 gap-8">

            <div className="col-span-1">
              <div className="w-full flex">
                <svg className="svg-icon w-1/6 mr-2" viewBox="0 0 20 20">
                  <path fill="#000" d="M11.174,14.993h1.647c0.228,0,0.412-0.184,0.412-0.411v-1.648c0-0.228-0.185-0.411-0.412-0.411h-1.647c-0.227,0-0.412,0.184-0.412,0.411v1.648C10.762,14.81,10.947,14.993,11.174,14.993 M3.759,13.346h4.943c0.227,0,0.412-0.184,0.412-0.412c0-0.228-0.185-0.411-0.412-0.411H3.759c-0.227,0-0.412,0.184-0.412,0.411C3.347,13.162,3.532,13.346,3.759,13.346 M3.759,14.993h3.295c0.228,0,0.412-0.184,0.412-0.411S7.282,14.17,7.055,14.17H3.759c-0.227,0-0.412,0.185-0.412,0.412S3.532,14.993,3.759,14.993 M14.881,5.932H1.7c-0.455,0-0.824,0.369-0.824,0.824v9.886c0,0.454,0.369,0.823,0.824,0.823h13.181c0.455,0,0.823-0.369,0.823-0.823V6.755C15.704,6.301,15.336,5.932,14.881,5.932M14.881,16.642H1.7v-5.767h13.181V16.642z M14.881,8.403H1.7V6.755h13.181V8.403z M18.176,2.636H4.995c-0.455,0-0.824,0.37-0.824,0.824v1.236c0,0.228,0.185,0.412,0.412,0.412c0.228,0,0.412-0.184,0.412-0.412V3.46h13.181v9.886H16.94c-0.228,0-0.412,0.185-0.412,0.412s0.185,0.412,0.412,0.412h1.235c0.455,0,0.824-0.369,0.824-0.824V3.46C19,3.006,18.631,2.636,18.176,2.636"></path>
                </svg>
                <h1 className="w-1/2 text-left text-2xl font-semibold">Ayuda Económica y Monetaria</h1>
              </div>

              <div className="w-full text-lg mt-4">
                Tu ayuda es muy importante para nosotros, por eso te ofrecemos 
                la posibilidad de realizar una transferencia bancaria a nuestra cuenta.
              </div>

              <div className="w-full rounded-md border border-gray-800 mt-4">
                <p className="p-2">
                  <strong>Nombre:</strong> El Amor Te Elije a Ti, IAP <br />
                  <strong>Banco:</strong> Bancomer <br />
                  <strong>Cuenta:</strong> 0102073471 <br />
                  <strong>CLABE:</strong> 012694001020734717 <br />
                  <strong>Denominación Social:</strong> El Amor te Elige a Ti IAP<br/>
                  <strong>Donataria autorizada con RFC:</strong> ATE150910BA0
                </p>
              </div>
            </div>

            <div className="col-span-1">
              <div className="w-full flex">
                <svg className="svg-icon w-1/6 mr-2" viewBox="0 0 20 20">
                  <path fill="#000" d="M7.93,4.509H9.62v1.689c0,0.233,0.189,0.422,0.422,0.422s0.422-0.189,0.422-0.422V4.509h1.689c0.233,0,0.423-0.189,0.423-0.422s-0.189-0.422-0.423-0.422h-1.689V1.975c0-0.233-0.189-0.422-0.422-0.422S9.62,1.742,9.62,1.975v1.689H7.93c-0.233,0-0.422,0.189-0.422,0.422S7.697,4.509,7.93,4.509 M18.489,8.311H1.595c-0.466,0-0.845,0.378-0.845,0.845V10c0,0.466,0.378,0.845,0.845,0.845h0.169l1.533,7.282l0.007-0.001c0.046,0.183,0.205,0.321,0.402,0.321h12.67c0.198,0,0.356-0.139,0.403-0.321l0.007,0.001l1.533-7.282h0.169c0.466,0,0.845-0.379,0.845-0.845V9.155C19.334,8.689,18.955,8.311,18.489,8.311 M2.626,10.845H5.53l0.266,1.689H2.982L2.626,10.845z M3.16,13.379h2.769l0.267,1.689H3.515L3.16,13.379z M4.049,17.603l-0.355-1.689h2.636l0.267,1.689H4.049z M9.62,17.603H7.441l-0.267-1.689H9.62V17.603z M9.62,15.068H7.041l-0.267-1.689H9.62V15.068z M9.62,12.534H6.641l-0.266-1.689H9.62V12.534z M12.644,17.603h-2.179v-1.689h2.446L12.644,17.603zM13.043,15.068h-2.579v-1.689h2.845L13.043,15.068z M10.464,12.534v-1.689h3.245l-0.266,1.689H10.464z M16.035,17.603h-2.548l0.268-1.689h2.636L16.035,17.603z M16.569,15.068h-2.682l0.267-1.689h2.77L16.569,15.068z M17.103,12.534h-2.814l0.267-1.689h2.903L17.103,12.534z M18.489,10H1.595V9.155h16.895V10z"></path>
                </svg>
                <h1 className="w-1/2 text-left text-2xl font-semibold">Ayuda con Cosas y Alimentos</h1>
              </div>

              <div className="w-full text-lg mt-4">
                Si tienes algo que donar, puedes contactarnos a través de nuestras redes sociales.
                Desde ser usadas por los niños hasta ser vendidas nos ayudan a seguir ayudando.
              </div>
            </div>

            <div className="col-span-1">
              <div className="w-full flex">
                <svg className="svg-icon w-1/6 mr-2" viewBox="0 0 20 20">
                  <path fill="#000" d="M14.584,1.617l-4.625,2.89L5.333,2.195L0.709,4.495v13.888l4.625-2.313l4.625,1.157l4.625-1.734l4.625,1.734
                  V3.93L14.584,1.617z M18.053,15.492l-3.469-1.157l-4.625,1.734l-4.625-1.155l-3.468,1.734V5.086l3.468-1.734l4.625,2.312
                  l4.625-2.891l3.469,1.734V15.492z M10.248,6.827c-0.16,0-0.29,0.163-0.29,0.363v6.781c0,0.201,0.129,0.363,0.29,0.363
                  c0.16,0,0.289-0.162,0.289-0.363V7.19C10.537,6.99,10.408,6.827,10.248,6.827z M5.623,5.093c-0.16,0-0.29,0.163-0.29,0.363v7.938
                  c0,0.201,0.129,0.363,0.29,0.363c0.16,0,0.289-0.162,0.289-0.363V5.456C5.912,5.256,5.783,5.093,5.623,5.093z M14.584,12.815
                  c0,0.2,0.129,0.363,0.289,0.363s0.289-0.163,0.289-0.363V4.878c0-0.2-0.129-0.364-0.289-0.364s-0.289,0.164-0.289,0.364V12.815z"></path>
                </svg>
                <h1 className="w-1/2 text-left text-2xl font-semibold">Ayuda a Encontrarnos</h1>
              </div>

              <div className="w-full text-lg mt-4">
                ¡Ayudar a que la gente sepa donde estamos!, Si tienes un negocio o conoces a alguien
                que quiere ayudar, no dudes en traerlo. Nosotros le damos la bienvenida a todo aquel 
                que quiera ayudar a nuestra causa.

              </div>
            </div>
          </div>
        </div>

        <div className="w-full mx-auto h-auto mb-10">
          <div className="flex-auto w-full p-10 text-black">
            <h1 className="text-left text-4xl font-semibold">¿Qué necesitamos?</h1>
          </div>

          <div className="mt-2">
            <div className="px-10">
              <ul className="border border-gray-200 rounded overflow-hidden shadow-md">
                <li className="need-list-element">Cuarto de vida diaria</li>
                <li className="need-list-element">Aires acondicionados</li>
                <li className="need-list-element">Paneles solares</li>
                <li className="need-list-element">Techo de lámina o material de concreto de preferencia</li>
                <li className="need-list-element">5 Laptops </li>
                <li className="need-list-element">Recurso para cubrir las rentas mensuales del local</li>
                <li className="need-list-element">Pago mensual de Terapeutas y asistentes de grupo</li>
                <li className="need-list-element">Media hectárea de terreno cerca de la ciudad con una promesa de construcción por parte de GMB Mark Pujol Floch</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="w-full h-auto flex items-center p-10">
          <div className="text-black text-center sm:text-left">
            <h1 className="text-4xl font-semibold mb-4">Objetivo social, donaciones y patrocinadores</h1>
            <p className="text-lg inline-block sm:block">
              Nuestra institución pretende tener un  programa terapéutico escolarizado, para que en un futuro 
              los niños (as) tengan un desarrollo integral, brindando servicios adicionales de formación 
              educativa y física como: Natación, baloncesto, futbol, pintura, danza, teatro, alimentos y 
              bebidas como parte de un  sistema integral de atención.<br /><br />

              Dada las condiciones económicas en nuestro estado de Quintana Roo, hay niños y 
              jóvenes sin atención terapéutica, alejados del entorno social que no logran 
              el máximo de sus capacidades, es por ello que uno de los objetivos de la 
              Institución es recaudar fondos de empresas, públicas y privadas, para poder 
              atender cada vez a más niños que padezcan alguna discapacidad. <br /><br /> 

              Es un creciente problema de salud  pública. Una persona con discapacidad presenta una 
              limitación  física, mental o cognitiva transitoria o permanente que le impide  
              desarrollar sus actividades normales como una persona común.
            </p>
          </div>
        </div>

        <div className="w-full  mb-10 h-auto flex items-center p-10">
          <div className="text-black text-center sm:text-left">
            <h1 className="text-4xl font-semibold mb-4">Información para donaciones</h1>
            <p className="text-lg inline-block sm:block">
              Las donaciones son 100% deducibles de impuestos.<br />

              Denominación Social: El Amor te Elige a Ti
              IAP , Donataria autorizada con RFC: ATE150910BA0<br />
              Supervisados por la junta de Asistencia Social Privada del Edo. De Quintana Roo.
              <br /><br />

              BBVA Bancomer
              El Amor te Elige a Ti  Cuenta: 0102073471
              Clabe: 012694001020734717
              <br /><br /> 

              elamorteeligeati.iap@hotmail.com<br />
              paypal.me/elamorteeligeati<br />
              Celular: 9841154170<br /> <br />

              SOMOS UNA INSTITUCIÓN DE ASISTENCIA SOCIAL PRIVADA SIN FINES DE LUCRO, 
              SIN APORTACIÓN GUBERNAMENTAL<br />

              Actualmente brindan atención a 20 niños con discapacidad intelectual, autismo y  dificultad de aprendizaje.

            </p>
          </div>
        </div>
      </Layout>
    </main>     
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Charity!</title>
